@font-face {
  font-family: "Leb_Medium";
  src: url("/phx/fonts/LabGrotesqueWebMedium/LabGrotesqueWeb-Medium.woff") format("woff");
}
@font-face {
  font-family: "Leb_Light";
  src: url("/phx/fonts/LabGrotesqueWebLight/LabGrotesqueWeb-Light.woff") format("woff");
}
/* Main Color (Cymra-Green) */
/* Second Color (Cymra-Yellow) */
/* Blue Range */
/* <- this is the main color of the blue range (used on index page) */
.cymra-bg-blue {
  background-color: #6ab7ff;
}
/* The Error Red */
/* Font and Icon Color (and background of the footer) */
/* Light Grey (background color of Library) */
/* Color of Sidebar */
/*  <- the dark background and selected sidebar tab */
/*  <- lighter grey for unselected sidebar tabs */
.cymra-bg-green {
  background-color: #48ffbc;
  color: #333;
}
.cymra-bg-gray {
  background-color: #eaeaea;
  color: #333;
}
.cymra-bg-none {
  background-color: #ffffff;
  color: #333;
}
body.front {
  background-color: #ffffff;
}
div.front-logo {
  border-bottom: 5px solid #48ffbc;
}
div.front-logo img {
  height: 100px;
}
div.side-info p {
  font-size: 1rem;
}
ul.front li {
  margin-left: 20px;
  padding-left: 10px;
  list-style: disc;
  border-left: 2px solid #48ffbc;
}
