/* Main Color (Cymra-Green) */
/* Second Color (Cymra-Yellow) */
/* Blue Range */
/* <- this is the main color of the blue range (used on index page) */
.cymra-bg-blue {
  background-color: #6ab7ff;
}
/* The Error Red */
/* Font and Icon Color (and background of the footer) */
/* Light Grey (background color of Library) */
/* Color of Sidebar */
/*  <- the dark background and selected sidebar tab */
/*  <- lighter grey for unselected sidebar tabs */
.cymra-bg-green {
  background-color: #48ffbc;
  color: #333;
}
.cymra-bg-gray {
  background-color: #eaeaea;
  color: #333;
}
.cymra-bg-none {
  background-color: #ffffff;
  color: #333;
}
@font-face {
  font-family: "Leb_Medium";
  src: url("/phx/fonts/LabGrotesqueWebMedium/LabGrotesqueWeb-Medium.woff") format("woff");
}
@font-face {
  font-family: "Leb_Light";
  src: url("/phx/fonts/LabGrotesqueWebLight/LabGrotesqueWeb-Light.woff") format("woff");
}
body.single_page nav {
  background-color: unset;
}
div.single_page navbar {
  background-color: #48ffbc;
}
