/* Main Color (Cymra-Green) */
/* Second Color (Cymra-Yellow) */
/* Blue Range */
/* <- this is the main color of the blue range (used on index page) */
.cymra-bg-blue {
  background-color: #6ab7ff;
}
/* The Error Red */
/* Font and Icon Color (and background of the footer) */
/* Light Grey (background color of Library) */
/* Color of Sidebar */
/*  <- the dark background and selected sidebar tab */
/*  <- lighter grey for unselected sidebar tabs */
.cymra-bg-green {
  background-color: #48ffbc;
  color: #333;
}
.cymra-bg-gray {
  background-color: #eaeaea;
  color: #333;
}
.cymra-bg-none {
  background-color: #ffffff;
  color: #333;
}
@font-face {
  font-family: "Leb_Medium";
  src: url("/phx/fonts/LabGrotesqueWebMedium/LabGrotesqueWeb-Medium.woff") format("woff");
}
@font-face {
  font-family: "Leb_Light";
  src: url("/phx/fonts/LabGrotesqueWebLight/LabGrotesqueWeb-Light.woff") format("woff");
}
section.archive a {
  color: #333;
  text-decoration: none;
}
section.archive article {
  margin-bottom: 10px;
  cursor: pointer;
  border-left: 3px solid #eaeaea;
  padding-left: 5px;
}
section.archive article:hover {
  border-left: 3px solid #48ffbc;
}
section.archive article div.info {
  color: #3f4643;
  font-size: 0.8rem;
}
article.blog-archive {
  position: relative;
  margin-bottom: 40px;
}
article.blog-archive img {
  position: absolute;
  top: 3px;
  left: 0px;
  width: 12px;
  margin-right: 10px;
}
article.blog-archive div.content p:first-of-type {
  font-family: 'Leb_Light', sans-serif;
}
