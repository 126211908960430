/**
  Colours and fonts
 */
@font-face {
  font-family: "Leb_Medium";
  src: url("/phx/fonts/LabGrotesqueWebMedium/LabGrotesqueWeb-Medium.woff") format("woff");
}
@font-face {
  font-family: "Leb_Light";
  src: url("/phx/fonts/LabGrotesqueWebLight/LabGrotesqueWeb-Light.woff") format("woff");
}
/* Main Color (Cymra-Green) */
/* Second Color (Cymra-Yellow) */
/* Blue Range */
/* <- this is the main color of the blue range (used on index page) */
.cymra-bg-blue {
  background-color: #6ab7ff;
}
/* The Error Red */
/* Font and Icon Color (and background of the footer) */
/* Light Grey (background color of Library) */
/* Color of Sidebar */
/*  <- the dark background and selected sidebar tab */
/*  <- lighter grey for unselected sidebar tabs */
.cymra-bg-green {
  background-color: #48ffbc;
  color: #333;
}
.cymra-bg-gray {
  background-color: #eaeaea;
  color: #333;
}
.cymra-bg-none {
  background-color: #ffffff;
  color: #333;
}
p {
  font-size: 1.2rem;
}
p strong {
  font-family: "Leb_Medium", sans-serif;
}
p .lg {
  font-size: 110%;
}
p .sm {
  font-size: 90%;
}
h1,
h2,
h3 {
  font-family: "Leb_Medium", sans-serif;
}
h1 {
  font-size: 1.4rem;
  margin-left: 20px;
}
h1 img {
  position: absolute;
  top: 3px;
  left: 15px;
  width: 12px;
  margin-right: 10px;
}
h1.title {
  margin-left: 0;
  font-size: 2.4rem;
}
h1.no-icon {
  margin-left: 0;
}
h2 {
  font-size: 1.2rem;
}
body {
  background-color: #ffffff;
  padding-bottom: 80px;
}
body .container img.logo {
  float: left;
  height: 90%;
}
body nav {
  font-size: 1rem;
  font-family: "Leb_Medium", sans-serif;
  background-color: #ffffff;
  margin-bottom: 80px;
}
body .welcome {
  background-image: url("/phx/images/front.png");
  background-size: cover;
  margin-bottom: 80px;
}
body .welcome p {
  font-size: 2.2rem;
}
.books {
  margin-bottom: 50px;
}
.books img {
  width: 100%;
  margin-bottom: 20px;
}
.books p {
  font-size: 1rem;
}
.cymra-btn {
  border-radius: 15px;
  color: #ffffff;
}
.cymra-btn:hover {
  background-color: #6a9bff;
}
.cymra-btn.cymra-btn-sm {
  font-size: 0.6rem;
  line-height: 120%;
}
a.cymra-btn:hover {
  color: #ffffff;
}
.footer {
  position: fixed;
  margin-top: -60px;
  /* negative value of footer height */
  bottom: 0;
  height: 60px;
  width: 100%;
  clear: both;
  padding-top: 10px;
  background-color: #3f4643;
}
article header {
  font-family: "Leb_Medium", sans-serif;
}
article header span.author,
article header span.date {
  font-family: 'Leb_Light', sans-serif;
}
article p {
  font-size: 1rem;
}
article div.content p:first-of-type {
  font-family: "Leb_Medium", sans-serif;
}
ul {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}
ul li {
  font-family: 'Leb_Light', sans-serif;
  font-size: 1.1rem;
}
ul li a {
  color: #1b1e21;
  text-decoration: none;
}
ul li a:hover {
  text-decoration: none;
  color: #333;
}
span.help-block {
  color: #ff5e5e;
  text-transform: capitalize;
}
.register {
  background-color: #333333;
}
.jumbotron h1 {
  margin-left: 0;
  font-size: 3rem;
}
.cymra-fullpage-dark {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #333;
  z-index: -2;
}
.w-px-150 {
  width: 150px;
}
.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
